<template>
  <div class="bg-colorWhite p-2" style="border-radius: 20px">
    <h3 class="font-weight-bolder text-colorBlack">My Timetable</h3>
    <p class="font-weight-bold text-colorGray mb-50">
      You can see your all subjects and lectures.
    </p>

    <full-calendar
      ref="refCalendar"
      :options="calendarOptions"
      class="full-calendar"
    />
  </div>

  <!-- <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div> -->
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import DayGridPlugin from "@fullcalendar/daygrid";
import TimeGridPlug from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    // CalendarEventHandler,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [DayGridPlugin, TimeGridPlug, interactionPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "sidebarToggle, prev,next, title",
          end: "timeGridDay,timeGridWeek,dayGridMonth",
        },
        events: null,
        editable: true,
        selectable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        weekends: false,
        eventSources: [],
        select: null,
        eventClick: null,
        dayMaxEvents: 5,
        eventClassNames: ({ event: calendarEvent }) => {
          const calendarsColor = {
            Business: "primary",
            Holiday: "success",
            Personal: "danger",
            Family: "warning",
            ETC: "info",
          };
          const colorName =
            calendarsColor[calendarEvent._def.extendedProps.calendar];
          return [
            // Background Color
            `bg-${colorName}`,
          ];
        },
        eventClick({ event: clickedEvent }) {},
        customButtons: {
          myCustomButton: {
            text: "Navigate by Year",
            click: function () {},
          },
        },
        dateClick(info) {},
        eventDrop({ event: droppedEvent }) {},
        eventResize({ event: resizedEvent }) {},
        rerenderDelay: 350,
      },
    };
  },
  async mounted() {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);
    }
  },
  // setup() {
  //   const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

  //   // Register module
  //   if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
  //     store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
  //       store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
  //   });

  //   const {
  //     refCalendar,
  //     isCalendarOverlaySidebarActive,
  //     event,
  //     clearEventData,
  //     addEvent,
  //     updateEvent,
  //     removeEvent,
  //     fetchEvents,
  //     refetchEvents,
  //     calendarOptions,

  //     // ----- UI ----- //
  //     isEventHandlerSidebarActive,
  //   } = useCalendar();

  //   // fetchEvents();

  //   return {
  //     refCalendar,
  //     isCalendarOverlaySidebarActive,
  //     event,
  //     clearEventData,
  //     addEvent,
  //     updateEvent,
  //     removeEvent,
  //     refetchEvents,
  //     calendarOptions,

  //     // ----- UI ----- //
  //     isEventHandlerSidebarActive,
  //   };
  // },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
